/** @jsxImportSource @emotion/react */
import { css, keyframes } from "@emotion/react";
import { large, medium } from "./Global.css";


export const InfoBarWrapperStyle = (open, side = "left") => css`
    width: 100%;
    position: absolute;
    min-height: 70%;
    bottom: 0px;
    top: 0px;
    display: ${open ? 'block' : 'none'};
    left: ${open ? '0' : '-100%'};
    background: linear-gradient(rgba(29, 29, 29,0.9) 0%, rgba(0, 0, 0,0.4) 100%);
    border-right-width: 0px;
    border-right-style: solid;
    border-image:linear-gradient(rgba(0,0,0, 0.3) 0%, rgba(0,0,0, 0.1) 100%) 1;
    scroll-snap-type: x mandatory;
    gap: 20px;
    overflow: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
    
    
    z-index: 1;
    display: flex;
    
    ${medium}{
        border-right-width: 1px;
        top: 0;
        max-width: 50%;
    }
    ${large}{
        /* pointer-events: none; */
        top: 0;
        width: 475px;
    }

    .innerScroll{
        height: 100%;
        padding: 0 15px 0 15px;
        scroll-snap-align: center;
        flex-shrink: 0; 
        ${medium}{
            padding: 0 15px 15px 15px;
        }
        box-sizing: border-box;
        
        overflow-y: scroll;
        width: 100%;
        &::-webkit-scrollbar {
            display: none;
        }
        > div{
            gap: 15px;
            width: 100%;
            display: flex;
            flex-direction: column;
            *{
                pointer-events: auto;
            }
            &:after{
                content: "";
                width: 100%;
                min-height: 1px;
                display: block;
            }
        }
    }
    

`

export const SwipeToCloseStyle = css`

    padding: 0 40px;
    color: transparent;
    

`

export const CloseInfoTabStyle = (side = "left") => css`

    position: absolute;
    ${side}: calc(100% + 2px);
    top: calc(50% - 27px);
    background: rgb(29, 29, 29,1);
    border: 1px solid rgba(254, 215, 0, 0.5);
    border-${side}: none;
    height: 85px;
    width: 30px;
    border-radius: ${side === "left" ?" 0 4px 4px 0" : "4px 0 0 4px" };
    color: rgba(255,255,255,0.4);
    padding: 0;
    cursor: pointer;
    pointer-events: auto;    
    font-size: 22px;
    svg{
        position: relative;
        ${side}:-1px;
            fill: rgba(255,255,255,0.6);
        width: 20px;
    }
    &:hover{
        svg{
            fill: rgba(255,255,255,1);
        }
        background: rgb(29, 29, 29,0.8);
    }

    ${large}{
        width: 20px;
        height: 55px;
        font-size: 16px;
    }



`

export const SwipableStyle = css`
    flex-shrink:0;
    scroll-snap-align: center;
`


export const InfoSectionStyle = css`

    padding: 10px;
    position: relative;
    color: #fff;
    border-radius: 3px;

    h2{
        font-family: "Inter";
        font-weight: 300;
        font-size: 20px;
        display:flex;
        justify-content: center;
        gap:5px;
        align-items: center;
    }

    h3{
        font-family: "Inter";
        font-weight: 400;
        font-size: 20px;
        text-align: center;
        ${medium}{
            font-size: 18px;
            text-align: left;
        }
    }

`
const bounce = keyframes`
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
`

export const SubtleAnimatedBackground = css`

    background: linear-gradient(
        135deg,
        rgba(100, 41, 155, 0.1) 0%,
        rgba(41, 51, 65, 0.6) 25%,
        rgba(20, 20, 200, 0.1) 50%,
        rgba(41, 51, 65, 0.6) 75%,
        rgba(100, 41, 130, 0.1) 100%
    );
    background-size: 400% 400%;
    animation: ${bounce} 60s ease infinite;
`

const auroraWave = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

export const NorthernLightsBackground = css`
  background: linear-gradient(
    45deg,
    rgba(40, 25, 40, 0.3) 0%,
    rgba(160, 10, 234, 0.1) 20%,
    rgba(0, 212, 255, 0.2) 50%,
    rgba(147, 51, 234, 0.15) 80%,
    rgba(10, 25, 47, 0.4) 100%
  ),
  rgba(0, 0, 0, 0.8);
  background-size: 400% 400%;
  animation: ${auroraWave} 15s ease infinite;
  box-shadow: inset 0 0 50px rgba(255, 255, 255, 0.1), 0 0 5px rgb(0, 0, 0, 0.9);
  
`;

export const DefaultBackground = css`
    background: linear-gradient(rgba(25, 33, 61, 0.8) 0%,  rgba(25, 33, 61, 0.4) 100%);
    box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.1), 0 0 5px rgb(0, 0, 0, 0.9);
`

export const SoftwareMinerStyle = css`
    fill: white;
`

export const InfoSectionHeadingStyle = css`

    position: relative;
    text-align:center;

    h2{
        text-transform: uppercase;
        font-size: 18px;
        ${medium}{
            font-size: 20px;
        }
    }

    .id{
        font-family: "Inter";
        font-size: 12px;
        padding-top: 3px;
        display:inline-flex;
        align-items: center;
        gap: 4px;
        color: #888;
        transition: all 0.15s ease;
        body.copied &{
            color: rgba(255,255,255,0.8);
            span{
                transform: scale(1.015);
            }
        }
    }

`

export const InfoSectionHeadingStyleBack = css`

    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 10%;
    text-align: left;
    &:hover{
        svg{
            fill: rgba(255,255,255,1);
        }
    }
    svg{
        height: 100%;
        fill: rgba(255,255,255,0.7);
    }

`

export const InfoSectionHeadingStyleClose = css`

    position: absolute;
    top: 4px;
    right: 5px;
    background:none;
    border:none;
    &:hover{
        svg{
            fill: rgba(255,255,255,1);
        }
    }
    svg{
        fill: rgba(255,255,255,0.7);
    }

`

export const InfoSectionListStyle = css`

    width: 100%;

    li{ 
        margin-bottom: 2%;
        
        a{
            display: block;
            color: #fff; 
            ${DefaultBackground}
            padding: 10px 10px 10px 20px;
            border-left: 4px solid #FED700;
            border-radius: 4px;
            text-align: left;
            text-decoration: none;
            &.active{
                background: rgba(0, 255, 132, 0.25);
                border-left: 4px solid rgba(0, 255, 132, 1);
            }
            &:hover{
                border-left: 4px solid rgba(0, 255, 132, 1);
                h2{
                    text-decoration: underline;
                    svg[class$="API"] {
                        fill: rgba(254, 215, 0, 0.6);
                    }
                    svg.Hardware{
                        fill: rgba(254, 215, 0, 0.6);
                        &.verified{
                            transform: scale(1.1) rotate(10deg);
                        }
                    }
                }
            }
            h2{
                margin-bottom: 2px;
                font-size: 24px;
                line-height: 30px;
                font-family: "Inter";
                text-transform: capitalize;
                display: flex;
                align-items: center;
                gap: 5px;
                justified-content: center;
                svg{
                    transition: all 0.2s;
                    width: 20px;
                    height: 20px;
                    position: relative;
                    top: 1px;
                    flex-shrink: 0;
                    flex-grow: 0;
                    
                    &[class$="API"] {
                        fill: rgba(254, 215, 0, 0.3);
                    }
                    &.Hardware{
                        fill: rgba(254, 215, 0, 0.3);
                    }
                }
                

            }
            span{
                color: rgba(255,255,255,0.8)
            }
        }
    }

`

export const InfoSectionListLoadMoreButton = css`
    background: rgba(145, 183, 255,0.2);
    border: none;
    border-radius: 4px;
    color: #fff; 
    font-size: 24px;
    line-height: 30px;
    font-family: "Inter";
    padding: 10px 10px 10px 20px;
    margin-bottom: 10px;
    border-left: 4px solid rgb(173, 144, 36);
    border-right: 4px solid rgb(173, 144, 36);
    cursor: pointer;
    width: 100%;
    &:hover{
        text-decoration: underline;
    }
`