/** @jsxImportSource @emotion/react */
import CopyToClipboardIcon from '../Icons/CopyToClipboardIcon';
import { css } from '@emotion/react';


const copyButtonStyle = css`
    border: none;
    background: none;
    padding: 0;
    margin:0;
    position: relative;
    top: 1px;
    svg{
        fill: #888;
        width: 12px;
        height: 15px
    }
    &:hover{
        svg{
            fill: #fff;
        }
    }

    body.copied &{
        svg{
            transform: scale(1.04);
        }
    }
`

const CopyToClipboard = ({text}) => {
    const copyToClipboard = () => {
        navigator.clipboard.writeText(text).then(() => {
            document.body.classList.add("copied");
            setTimeout(() => {
                document.body.classList.remove("copied");
            }, 300);
        }).catch(err => {
            console.log('Failed to copy: ', err);
        });
    };

    return (
        <button css={copyButtonStyle} onClick={copyToClipboard}>
            <CopyToClipboardIcon />  
        </button>
    );
};

export default CopyToClipboard;