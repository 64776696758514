import React, { useEffect, useState, useCallback } from "react";
import { formatISO } from "date-fns";

export const ExplorerContext = React.createContext();

const ExplorerContextProvider = ({ children }) => {

  const [hexes, setHexes] = useState([])
  const [infoBarOpen, setInfoBarOpen] = useState(false)
  const [dataBarOpen, setDataBarOpen] = useState(false)
  const [currentHex, setCurrentHex] = useState({hex: null});
  const [mapLoaded, setMapLoaded] = useState(false)

  useEffect(() => {
    loadHexes((hexData) => setHexes(hexData.data.map.hexes.map(h => h.h3Index)))
  }, []);


  const closeDataBar = useCallback(() => {
    setDataBarOpen(false)
  }, [])
  const openDataBar = useCallback(() => {
    setDataBarOpen(true)
    setInfoBarOpen(false)
  }, [])

  const closeInfoBar = useCallback(() => {
    setInfoBarOpen(false)
    setCurrentHex({hex: null})
  }, [])
  const openInfoBar = useCallback(() => {
    setInfoBarOpen(true)
    setDataBarOpen(false)
  }, [])

  return (
    <ExplorerContext.Provider
      value={{
        hexes: hexes,
        infoBarOpen: infoBarOpen,
        dataBarOpen: dataBarOpen,
        closeInfoBar: closeInfoBar,
        closeDataBar: closeDataBar,
        openInfoBar: openInfoBar,
        openDataBar: openDataBar,
        loadHexGateways: loadHexGateways,
        loadGateway: loadGateway,
        loadHistogramProductionData: loadHistogramProductionData,
        loadDailykWhProductionData: loadDailykWhProductionData,
        loadBlocks: loadBlocks,
        loadBlock: loadBlock,
        loadStats: loadStats,
        bulkQueryNames: bulkQueryNames,
        currentHex,
        setCurrentHex,
        mapLoaded,
        setMapLoaded,
        
        focusAndFindGw: async (serial) => {
          const gw = await loadGateway(serial)
          setCurrentHex({ index: gw.h3Index, focus: true })
          setInfoBarOpen(true)
        }
      }}
    >
      {children}
    </ExplorerContext.Provider>
  )
}

const executeRequest = async (query) => {
  const response = await fetch(
    // 'http://localhost:5000/',{
    'https://api.srcful.dev/', {
    method: 'post',
    body: query,
    headers: {
      'Content-Type': 'application/json',
      'Content-Length': query.length,
    }
  }
  );
  return response.json();
}

const loadBlocks = async (lastBlockId) => {
  const data = JSON.stringify({
    query: `{
      blocks{
        list${lastBlockId ? `(fromBlock:${lastBlockId})` : ""}{
          block
          endTimestamp
          transactions{
            serial
          }
        }
      }
    }`});
  return await executeRequest(data)
}

const loadStats = async () => {

  const data = JSON.stringify({
    query: `{
      stats {
        gatewaysOnline
        currentKW
      }
    }`});
  return await executeRequest(data)
}

const bulkQueryNames = async (serials) => {

  const data = JSON.stringify({
    query: `{
      gatewayConfiguration{
        ${serials.map(s => `serial_${s}: gatewayName(id:"${s}"){name}`)}
      }
    }`});
  
  const bulkResponse = await executeRequest(data)
  const response = {};
  Object.keys(bulkResponse.data.gatewayConfiguration)
    .forEach(m => response[m.replace("serial_","")] = bulkResponse.data.gatewayConfiguration[m].name)
  return response;
}

const loadBlock = async (blockId) => {
  const data = JSON.stringify({
    query: `{
      blocks{
        block(blockId:${blockId}){
          hashData
          hashPrevBlock
          startTimestamp
          endTimestamp
          transactions{
            serial
            rewardTypes{
              power
              dataPoints
              srcAllocation
            }
          }
          data{
            totalSrcDistribution
            pop{
              totalProduction
              totalDataPoints
            }
          }
        }
        
      }
    }`});
  return await executeRequest(data)
}

const loadHexes = async (callback) => {

  const data = JSON.stringify({
    query: `{
            map{
             hexes{
               h3Index
             }
           }
        }`});
  callback(await executeRequest(data));
}

const loadHexGateways = async (hex, callback) => {
  const data = JSON.stringify({
    query: `{
            gateway{
              gateways(hex:"${hex}",take:100){
                name
                id
                links
                typeOf
              }
            }
          }`});
  callback(await executeRequest(data));
}

const loadGateway = async (gwId) => {
  const rewardStartDate = formatISO(new Date(new Date() - (30 * 86400000)), { representation: 'date' })
  const query = JSON.stringify({
    query: `{
      gateway{
        gateway(id:"${gwId}"){
          h3Index
          name
          id,
          wallet
          timeZone
          typeOf
          links
          firmwareVersion
          inverterData {
            selectedMake
            model
            sn
            ratedPower
          }
        }
      }
      token{
        rewards(id:"${gwId}"){
          total
          betaPeriod{
            when
            src
            daysOnline
          }
        }
      }
    }`}
  )
  const data = await executeRequest(query)
  let gwData = data.data.gateway.gateway

  return {...gwData,...data.data.token, ...{rewardStartDate}}
}

const loadHistogramProductionData = async (gwId, startDate, resolution, timeZone, includeNullData, validationGwId) => {

  const validationQuery = validationGwId ? `
    validationData: proofOfSource(id:"${validationGwId}"){
      histogram(start: "${startDate}",stop: "${formatISO(new Date())}", resolution: "${resolution}"){
        when
        power
      }
    }
  ` : "";

  const query = JSON.stringify({
    query: `{
      proofOfSource(id:"${gwId}"){
        histogram(start: "${startDate}",stop: "${formatISO(new Date())}", resolution: "${resolution}", includeNullData: ${includeNullData}){
          when
          power
        }
        latest{
          when
          power
        }
        today(tz:"${timeZone}")
      }
      ${validationQuery}
    }`
  });
  const data = await executeRequest(query)
  return data.data
}

const loadDailykWhProductionData = async (gwId, startDate, timeZone) => {
  
  const query = JSON.stringify({
    query: `{
      proofOfSource(id:"${gwId}"){
        dailykWh(start:"${startDate}"){
          when
          power
        }
        latest{
          when
          power
        }
        today(tz:"${timeZone}")
      }
    }`});
  const data = await executeRequest(query)
  return data.data

}




export default ExplorerContextProvider