
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
    TimeScale,
    BarElement
  } from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
    TimeScale,
    BarElement
  );

//as chartjs js will not print null we need to give it a value that we later can identify as null and paint red... we use 0.001337
//as only only two fractions are printed we can use 0.001337 as a value that will not be printed.
//im very sorry about this hack... it is what it is.
const numberOfDeath = 0.001337;


export const options = (tooltipFormat,startTime) => { return {
    responsive: true,
    cubicInterpolationMode: "monotone",
    plugins: {
        legend: {
            labels: {
                color: 'white',
                boxWidth: 0,
            }
        },
        tooltip:{
            callbacks:{
                label: function(context) {
                    if(context.raw === numberOfDeath) return "No data";
                    return context.formattedValue + " " + context.dataset.label;
                },
                labelColor: function(context) {
                    if(context.raw === numberOfDeath) return { borderColor: "transparent", backgroundColor: "rgba(255, 122, 0, 0.8)" };
                    return { borderColor: context.dataset.pointBorderColor, backgroundColor: context.dataset.pointBackgroundColor };
                }
            }
        },
        offsetPlugin: {
            id: 'offsetPlugin',
            afterDatasetsDraw: (chart) => {
              chart.data.datasets.forEach((dataset, i) => {
                if (dataset.offset) {
                  const meta = chart.getDatasetMeta(i);
                  meta.data.forEach((dataPoint) => {
                    dataPoint.y -= 10005;
                  });
                }
              });
            }
          }
    },
    scales: {
        x: {
            type: 'time',
            min: startTime,
            max: new Date(),
            time: {
                tooltipFormat: tooltipFormat,
                displayFormats: { hour: 'HH:mm' },
            },
            beginAtZero: true,
            ticks:{
                color: "#FAF9F6",
            },
            grid: {
                display: false
            },
            border: {
                color: "transparent"
            }
        },
        y: {
            ticks:{
                color: "#FAF9F6",
                count: 5,
                format: { maximumFractionDigits: 2, minimumFractionDigits: 0 }
            },
            min: 0,
            border: {
                color: "black"
            }
        }
    }
  }};


const dataSetColors = {
    "hardware": {
        borderColor: 'rgba(255, 229, 0, 0.8)',
        backgroundColor: 'rgba(255, 229, 0, 0.5)',
        pointBackgroundColor: 'rgba(173, 144, 36, 0.8)',
    },
    "software": {
        borderColor: 'rgba(1, 126, 122, 0.8)',
        backgroundColor: 'rgba(1, 126, 122, 0.5)',
        pointBackgroundColor: 'rgba(137, 176, 199, 0.8)',
    }
}


const ProductionGraph = ({data, type = "line", tooltipFormat="yyyy-MM-dd HH:mm", label = "kW", startTime, colorMode, validationData}) => {
    
    if(!data) return null;

    const labels = data.map(x => x.when);
    const dataset = data.map(x => {
        if(x.power === null) return numberOfDeath;
        if(type === "line") return x.power/1000
        return x.power
    });

    let validationDataSet = {
        label: "",
    }
    if(validationData && type === "line"){
        validationDataSet = {
            label: "",
            data: validationData.map(x => {
                if(x.power === null) return {x: x.when, y: 0}
                return {x: x.when, y: (x.power/1000)* 1.1};
                
            }),
            spanGaps: false,
            fill: false,
            pointBorderColor: 'transparent',
            pointRadius: 0,
            pointHoverRadius: 0,
            pointHitRadius: 0,
            borderWidth: 2,
            borderColor: '#017E7A',
        };
        
    }

    const ChartType = type === "line" ? Line : Bar;

    return <ChartType options={options(tooltipFormat,startTime)} data={{
        labels: labels,
        datasets: [validationDataSet,{
            label: label,
            data: dataset,
            spanGaps: false,
            fill:true,
            ...dataSetColors[colorMode],
            pointBorderColor: 'transparent',
            pointRadius: 4,
            pointStyle: false,
            segment: {
                borderColor: ctx => {
                    if(ctx.p0.raw === numberOfDeath && ctx.p1.raw === numberOfDeath) return "rgba(255, 122, 0, 0.8)";
                    return undefined;
                }
            }
        }]
    }}  />

}



export default ProductionGraph;